<template>
    <v-app>
        <app-navigation
            v-if="!getHideNav && getIsAuthenticated"
            class="hidden-print"
        />
        <v-main>
            <v-scroll-y-transition mode="out-in">
                <router-view />
            </v-scroll-y-transition>
        </v-main>
    </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import { mapGetters } from 'vuex';

export default {
    components: { AppNavigation },
    computed: {
        ...mapGetters(['getIsAuthenticated', 'getHideNav']),
    },
};
</script>

<style scoped>
.theme--light.v-application {
    background: linear-gradient(
        0deg,
        rgba(33, 150, 243, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

@media print {
    .theme--light .v-application {
        background: #ffffff !important;
    }
}
</style>
