import router from '@/router';
import axios from 'axios';

export default {
    async checkLoggedIn({ commit }) {
        if (localStorage.getItem('isLoggedIn') == 'true') {
            console.log('Checked login status');
            commit('setIsAuthenticated', true);
            return true;
        } else {
            console.error('Could not login');
            commit('setIsAuthenticated', false);
            return false;
        }
    },
    async initialize({ dispatch }) {
        // Functions on app load
        dispatch('customerRead');
        // dispatch('employeeRead');
        dispatch('categoryRead');
        dispatch('itemRead');
        // dispatch('orderStatusRead');
        // dispatch('paymentTypeRead');
        // dispatch('registerRead');
        // dispatch('orderRead');
    },
    async logout({ commit }) {
        try {
            // await axios.post(`${getters.getInternalLogoutApi}`);

            console.log('Logged out!');
            commit('setIsAuthenticated', false);
            localStorage.setItem('isLoggedIn', 'false');
            localStorage.setItem('authToken', '');
        } catch (error) {
            console.error('Could not logout');
            console.error(error);
        }

        await router.push('/login');
    },

    // Items
    async itemCreate({ getters, commit }, item) {
        console.log('Item:');
        console.log(item);

        try {
            let response = await axios.post(
                `${getters.getInternalItemApi}`,
                item,
            );

            console.log('response');
            console.log(response);

            if (response.data.success) {
                commit('addItem', response.data.data);
            }

            return response.data;
        } catch (error) {
            commit('setItems', []);
        }
    },
    async itemRead({ getters, commit }) {
        const timeNow = Date.now();
        const lastRequestTime = getters.getLastRequestTime.items;

        if (lastRequestTime != 0 && timeNow - lastRequestTime < 1000) {
            return;
        }

        commit('setLastRequestTime', {
            name: 'items',
            value: Date.now(),
        });

        try {
            let response = await axios.get(
                `${getters.getInternalItemApi}`,
                null,
            );
            commit('setItems', response.data.data);
        } catch (error) {
            commit('setItems', []);
        }
    },
    async itemUpdate({ getters, commit }, item) {
        try {
            item.append('_method', 'PUT');
            let response = await axios.post(
                `${getters.getInternalItemApi + '/' + item.get('itemId')}`,
                item,
            );

            if (response.data.success) {
                commit('updateItem', response.data.data);
            }

            return response.data;
        } catch (error) {
            console.error('Could not update item');
            console.error(error);
        }
    },
    async itemDelete({ getters }, itemId) {
        try {
            let response = await axios.delete(
                `${getters.getInternalItemApi + '/' + itemId}`,
                null,
            );

            return response.data.success;
        } catch (error) {
            console.error('Could not delete item');
            console.error(error);
        }
    },

    // Categories
    async categoryRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalCategoryApi}`,
                null,
            );
            commit('setCategories', response.data.data);
        } catch (error) {
            commit('setCategories', []);
        }
    },

    async categoryCreate({ getters, commit }, category) {
        console.log('Category:');
        console.log(category);

        try {
            let response = await axios.post(
                `${getters.getInternalCategoryApi}`,
                category,
            );

            console.log('response');
            console.log(response);

            if (response.data.success) {
                commit('addCategory', response.data.data);
            }

            return response.data;
        } catch (error) {
            console.error('Could not create category');
            console.error(error);
        }
    },

    async categoryUpdate({ getters, commit }, category) {
        try {
            category.append('_method', 'PUT');
            let response = await axios.post(
                `${
                    getters.getInternalCategoryApi +
                    '/' +
                    category.get('categoryId')
                }`,
                category,
            );

            if (response.data.success) {
                commit('updateCategory', response.data.data);
            }

            return response.data;
        } catch (error) {
            console.error('Could not update category');
            console.error(error);
        }
    },
    async categoryDelete({ getters }, categoryId) {
        try {
            let response = await axios.delete(
                `${getters.getInternalCategoryApi + '/' + categoryId}`,
                null,
            );

            return response.data.success;
        } catch (error) {
            console.error('Could not delete category');
            console.error(error);
        }
    },

    // Customers
    async customerRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalCustomerApi}`,
                null,
            );
            commit('setCustomers', response.data.data);
        } catch (error) {
            commit('setCustomers', []);
        }
    },

    async customerCreate({ getters, commit }, customer) {
        console.log('Customer:');
        console.log(customer);

        try {
            let response = await axios.post(
                `${getters.getInternalCustomerApi}`,
                customer,
            );

            console.log('response');
            console.log(response);

            if (response.data.success) {
                commit('addCustomer', response.data.data);
            }

            return response.data;
        } catch (error) {
            console.error('Could not create customer');
            console.error(error);
        }
    },

    async customerUpdate({ getters, commit }, customer) {
        try {
            customer.append('_method', 'PUT');
            let response = await axios.post(
                `${
                    getters.getInternalCustomerApi +
                    '/' +
                    customer.get('customerId')
                }`,
                customer,
            );

            if (response.data.success) {
                commit('updateCustomer', response.data.data);
            }

            return response.data;
        } catch (error) {
            console.error('Could not update customer');
            console.error(error);
        }
    },
    async customerDelete({ getters }, customerId) {
        try {
            let response = await axios.delete(
                `${getters.getInternalCustomerApi + '/' + customerId}`,
                null,
            );

            return response.data.success;
        } catch (error) {
            console.error('Could not delete customer');
            console.error(error);
        }
    },

    // Employees
    async employeeRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalEmployeeApi}`,
                null,
            );
            commit('setEmployees', response.data.data);
        } catch (error) {
            commit('setEmployees', []);
        }
    },

    // Orders
    async orderRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalOrderApi}`,
                null,
            );
            commit('setOrders', response.data.data);
        } catch (error) {
            commit('setOrders', []);
        }
    },

    // Payment Types
    async paymentTypeRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalPaymentTypeApi}`,
                null,
            );
            commit('setPaymentTypes', response.data.data);
        } catch (error) {
            commit('setPaymentTypes', []);
        }
    },

    // Order Statuses
    async orderStatusRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalOrderStatusApi}`,
                null,
            );
            commit('setOrderStatuses', response.data.data);
        } catch (error) {
            commit('setOrderStatuses', []);
        }
    },

    // Registers
    async registerRead({ getters, commit }) {
        try {
            let response = await axios.get(
                `${getters.getInternalRegisterApi}`,
                null,
            );
            commit('setRegisters', response.data.data);
        } catch (error) {
            commit('setRegisters', []);
        }
    },
};
