<template>
    <div>
        <v-navigation-drawer v-model="drawer" width="200" bottom app temporary>
            <v-list>
                <v-list-item-group
                    v-model="itemGroup"
                    active-class="blue--text"
                >
                    <template v-for="(item, index) in items">
                        <template>
                            <template v-if="item.subItems.length > 0">
                                <v-list-group
                                    :key="index"
                                    :prepend-icon="item.icon"
                                    :group="item.route"
                                    no-action
                                >
                                    <template v-slot:activator>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item
                                        v-for="(
                                            subItem, subIndex
                                        ) in item.subItems"
                                        :key="subIndex"
                                        :to="'/' + subItem.route"
                                    >
                                        <v-list-item-title>
                                            {{ subItem.title }}
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ subItem.icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-else>
                                <v-list-item
                                    :key="index"
                                    :to="'/' + item.route"
                                    active-class="blue--text"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ item.icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </template>
                </v-list-item-group>
            </v-list>
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn
                        block
                        color="red"
                        class="white--text"
                        @click="logout"
                    >
                        <v-icon left>logout</v-icon>
                        Logout
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app color="blue" dark dense>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: 'coreSTORE Express',
            drawer: false,
            itemGroup: null,
            items: [
                {
                    title: 'Register',
                    icon: 'mdi-point-of-sale',
                    route: 'register/sale',
                    subItems: [],
                },
                {
                    title: 'Orders',
                    icon: 'mdi-file-document-multiple',
                    route: 'order/list',
                    subItems: [],
                },
                {
                    title: 'Items',
                    icon: 'mdi-tag-text',
                    route: 'item/list',
                    subItems: [],
                },
                {
                    title: 'Categories',
                    icon: 'mdi-shape',
                    route: 'item/category/list',
                    subItems: [],
                },
                {
                    title: 'Customers',
                    icon: 'mdi-card-account-details',
                    route: 'customer/list',
                    subItems: [],
                },
                {
                    title: 'Settings',
                    icon: 'mdi-cog',
                    route: 'settings',
                    subItems: [],
                },
                {
                    title: 'Support',
                    icon: 'mdi-lifebuoy',
                    route: 'support',
                    subItems: [],
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['getAppColors']),
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        },
    },
};
</script>
