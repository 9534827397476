import Vue from 'vue';
import Vuex from 'vuex';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        colors: {
            primary: '#00ad54', // either seem to work, black is safer
            primaryDark: '#007c28', // either seem to work, white is safer
            secondary: '#005620', // requires white text
            secondaryLight: '#3b8449', // either, white is safer
            accent: '#56e082', // requires black text
        },
        isAuthenticated: false,
        hideNav: false,
        currentLocationId: '',
        currentLocationName: '',
        authenticatedLocations: [],
        quickDateRangeChoices: [
            {
                text: 'Today',
                value: 'today',
                useForBlockchyp: true,
                days: null,
            },
            {
                text: 'Yesterday',
                value: 'yesterday',
                useForBlockchyp: true,
                days: null,
            },
            {
                text: 'Year To Date',
                value: 'yearToDate',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Current Week',
                value: 'thisWeek',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Current Month',
                value: 'thisMonth',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Current Year',
                value: 'thisYear',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Last Week',
                value: 'previousWeek',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Last Month',
                value: 'previousMonth',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Last Year',
                value: 'previousYear',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Past 7 Days',
                value: 'last_7_d',
                useForBlockchyp: true,
                days: 7,
            },
            {
                text: 'Past 30 Days',
                value: 'last_30_d',
                useForBlockchyp: true,
                days: 30,
            },
            {
                text: 'Past 60 Days',
                value: 'last_60_d',
                useForBlockchyp: true,
                days: 60,
            },
            {
                text: 'Past 90 Days',
                value: 'last_90_d',
                useForBlockchyp: true,
                days: 90,
            },
            {
                text: 'Past 6 Months',
                value: 'last_6_M',
                useForBlockchyp: true,
                days: 180,
            },
            {
                text: 'Past 1 Year',
                value: 'last_1_y',
                useForBlockchyp: true,
                days: 365,
            },
            {
                text: 'Past 2 Years',
                value: 'last_2_y',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'Past 5 Years',
                value: 'last_5_y',
                useForBlockchyp: false,
                days: null,
            },
            {
                text: 'All Time',
                value: 'allOfTime',
                useForBlockchyp: false,
                days: null,
            },
        ],
        lastRequestTime: {
            items: 0,
        },
        categories: [],
        customers: [],
        employees: [],
        items: [],
        orders: [],
        orderStatuses: [],
        paymentTypes: [],
        registers: [],
    },
    mutations,
    actions,
    getters,
});
