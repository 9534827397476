import Vue from 'vue';

export default {
    setIsAuthenticated(state, payload) {
        state.isAuthenticated = payload;
    },
    setHideNav(state, payload) {
        state.hideNav = payload;
    },
    setLastRequestTime(state, payload) {
        state.lastRequestTime[payload.name] = payload.value;
    },

    // Categories
    setCategories(state, payload) {
        state.categories = payload;
    },
    addCategory(state, payload) {
        state.categories.push(payload);
    },
    updateCategory(state, payload) {
        let categoryIndex = state.categories.findIndex(
            (category) => category.categoryId === payload.categoryId
        );

        if (categoryIndex > -1) {
            Vue.set(state.categories, categoryIndex, payload);
        } else {
            console.log('Update Category Error: Category index not found.');
        }
    },
    deleteCategory(state, payload) {
        let categoryIndex = state.categories.findIndex(
            (category) => category.categoryId === payload.categoryId
        );

        if (categoryIndex > -1) {
            state.categories.splice(categoryIndex, 1);
        } else {
            console.log('Delete Category Error: Category index not found.');
        }
    },

    // Customers
    setCustomers(state, payload) {
        state.customers = payload;
    },
    addCustomer(state, payload) {
        state.customers.push(payload);
    },
    updateCustomer(state, payload) {
        let customerIndex = state.customers.findIndex(
            (customer) => customer.customerId === payload.customerId
        );

        if (customerIndex > -1) {
            Vue.set(state.customers, customerIndex, payload);
        } else {
            console.log('Update Customer Error: Customer index not found.');
        }
    },
    deleteCustomer(state, payload) {
        let customerIndex = state.customers.findIndex(
            (customer) => customer.customerId === payload.customerId
        );

        if (customerIndex > -1) {
            state.customers.splice(customerIndex, 1);
        } else {
            console.log('Delete Customer Error: Customer index not found.');
        }
    },

    // Employees
    setEmployees(state, payload) {
        state.employees = payload;
    },
    addEmployee(state, payload) {
        state.employees.push(payload);
    },
    updateEmployee(state, payload) {
        let employeeIndex = state.employees.findIndex(
            (employee) => employee.employeeId === payload.employeeId
        );

        if (employeeIndex > -1) {
            Vue.set(state.employees, employeeIndex, payload);
        } else {
            console.log('Update Employee Error: Employee index not found.');
        }
    },
    deleteEmployee(state, payload) {
        let employeeIndex = state.employees.findIndex(
            (employee) => employee.employeeId === payload.employeeId
        );

        if (employeeIndex > -1) {
            state.employees.splice(employeeIndex, 1);
        } else {
            console.log('Delete Employee Error: Employee index not found.');
        }
    },

    // Items
    setItems(state, payload) {
        state.items = payload;
    },
    addItem(state, payload) {
        state.items.push(payload);
    },
    updateItem(state, payload) {
        let itemIndex = state.items.findIndex(
            (item) => item.itemId === payload.itemId
        );

        if (itemIndex > -1) {
            Vue.set(state.items, itemIndex, payload);
        } else {
            console.log('Update Item Error: Item index not found.');
        }
    },
    deleteItem(state, payload) {
        let itemIndex = state.items.findIndex(
            (item) => item.itemId === payload.itemId
        );

        if (itemIndex > -1) {
            state.items.splice(itemIndex, 1);
        } else {
            console.log('Delete Item Error: Item index not found.');
        }
    },

    // Orders
    setOrders(state, payload) {
        state.orders = payload;
    },
    addOrder(state, payload) {
        state.orders.push(payload);
    },
    updateOrder(state, payload) {
        let orderIndex = state.orders.findIndex(
            (order) => order.orderId === payload.orderId
        );

        if (orderIndex > -1) {
            Vue.set(state.orders, orderIndex, payload);
        } else {
            console.log('Update Order Error: Order index not found.');
        }
    },
    deleteOrder(state, payload) {
        let orderIndex = state.orders.findIndex(
            (order) => order.orderId === payload.orderId
        );

        if (orderIndex > -1) {
            state.orders.splice(orderIndex, 1);
        } else {
            console.log('Delete Order Error: Order index not found.');
        }
    },

    // Order Statuses
    setOrderStatuses(state, payload) {
        state.orderStatuses = payload;
    },
    addOrderStatus(state, payload) {
        state.orderStatuses.push(payload);
    },
    updateOrderStatus(state, payload) {
        let orderStatusIndex = state.orderStatuses.findIndex(
            (orderStatus) => orderStatus.orderStatusId === payload.orderStatusId
        );

        if (orderStatusIndex > -1) {
            Vue.set(state.orderStatuses, orderStatusIndex, payload);
        } else {
            console.log(
                'Update Order Status Error: Order Status index not found.'
            );
        }
    },
    deleteOrderStatus(state, payload) {
        let orderStatusIndex = state.orderStatuses.findIndex(
            (orderStatus) => orderStatus.orderStatusId === payload.orderStatusId
        );

        if (orderStatusIndex > -1) {
            state.orderStatuses.splice(orderStatusIndex, 1);
        } else {
            console.log(
                'Delete Order Status Error: Order Status index not found.'
            );
        }
    },

    // Payment Types
    setPaymentTypes(state, payload) {
        state.paymentTypes = payload;
    },
    addPaymentType(state, payload) {
        state.paymentTypes.push(payload);
    },
    updatePaymentType(state, payload) {
        let paymentTypeIndex = state.paymentTypes.findIndex(
            (paymentType) => paymentType.paymentTypeId === payload.paymentTypeId
        );

        if (paymentTypeIndex > -1) {
            Vue.set(state.paymentTypes, paymentTypeIndex, payload);
        } else {
            console.log(
                'Update Payment Type Error: Payment Type index not found.'
            );
        }
    },
    deletePaymentType(state, payload) {
        let paymentTypeIndex = state.paymentTypes.findIndex(
            (paymentType) => paymentType.paymentTypeId === payload.paymentTypeId
        );

        if (paymentTypeIndex > -1) {
            state.paymentTypes.splice(paymentTypeIndex, 1);
        } else {
            console.log(
                'Delete Payment Type Error: Payment Type index not found.'
            );
        }
    },

    // Registers
    setRegisters(state, payload) {
        state.registers = payload;
    },
    addRegister(state, payload) {
        state.registers.push(payload);
    },
    updateRegister(state, payload) {
        let registerIndex = state.registers.findIndex(
            (register) => register.registerId === payload.registerId
        );

        if (registerIndex > -1) {
            Vue.set(state.registers, registerIndex, payload);
        } else {
            console.log('Update Register Error: Register index not found.');
        }
    },
    deleteRegister(state, payload) {
        let registerIndex = state.registers.findIndex(
            (register) => register.registerId === payload.registerId
        );

        if (registerIndex > -1) {
            state.registers.splice(registerIndex, 1);
        } else {
            console.log('Delete Register Error: Register index not found.');
        }
    },
};
