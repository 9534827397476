export default {
    getInternalBaseUrl: () => {
        return 'https://expressapi.corestore.shop';
        // return 'http://127.0.0.1:8000';
    },
    getInternalControllerPrefix: () => {
        return '/index.php/';
    },
    getInternalApiPrefix: () => {
        // Change this if we set up multiple APIs
        return process.env.NODE_ENV === 'development' ? '/api/' : '/api/';
    },
    getInternalLoginApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'login'
        );
    },
    getInternalLogoutApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'logout'
        );
    },
    getInternalUserRegisterApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'user-register'
        );
    },
    getInternalCategoryApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'categories'
        );
    },
    getInternalCustomerApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'customers'
        );
    },
    getInternalEmployeeApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'employees'
        );
    },
    getInternalItemApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'items'
        );
    },
    getInternalOrderApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl + getters.getInternalApiPrefix + 'orders'
        );
    },
    getInternalOrderItemApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'order-items'
        );
    },
    getInternalOrderPaymentApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'order-payments'
        );
    },
    getInternalOrderStatusApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'order-statuses'
        );
    },
    getInternalPaymentTypeApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'payment-types'
        );
    },
    getInternalRegisterApi: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalApiPrefix +
            'registers'
        );
    },
    getInternalLoginController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'login'
        );
    },
    getAppColors: (state) => {
        return state.colors;
    },
    getIsAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    getHideNav: (state) => {
        return state.hideNav;
    },
    getQuickDateRangeChoices: (state) => {
        return state.quickDateRangeChoices;
    },
    getLastRequestTime: (state) => {
        return state.lastRequestTime;
    },
};
