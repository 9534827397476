import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/auth/LoginScreen'),
        meta: {
            authRequired: false,
            hideNav: true,
        },
        beforeEnter: (to, from, next) => {
            if (store.getters.getIsAuthenticated) {
                next({
                    path: '/register/sale',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/user-register',
        name: 'UserRegister',
        component: () =>
            import(
                /* webpackChunkName: "userRegister" */ '../views/auth/UserRegisterScreen'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
        beforeEnter: (to, from, next) => {
            if (store.getters.getIsAuthenticated) {
                next({
                    path: '/register/sale',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () =>
            import(
                /* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPassword.vue'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/reset-password/:reset_key',
        name: 'ResetPassword',
        component: () =>
            import(
                /* webpackChunkName: "resetPassword" */ '../views/auth/ResetPassword.vue'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/',
        alias: '/register',
        component: () =>
            import(
                /* webpackChunkName: "register" */ '../views/register/RegisterScreen'
            ),
        children: [
            {
                path: '/',
                alias: 'sale',
                name: 'Register',
                component: () =>
                    import(
                        /* webpackChunkName: "register" */ '../views/register/RegisterSale'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/item',
        name: 'Item',
        component: () =>
            import(/* webpackChunkName: "item" */ '../views/item/ItemScreen'),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "item" */ '../views/item/ItemList'
                    ),
            },
            {
                path: 'category/list',
                component: () =>
                    import(
                        /* webpackChunkName: "item" */ '../views/item/CategoryList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/category/list',
        name: 'Category',
        component: () =>
            import(
                /* webpackChunkName: "category" */ '../views/item/CategoryList'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/customer',
        name: 'Customer',
        component: () =>
            import(
                /* webpackChunkName: "customer" */ '../views/customer/CustomerScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "customer" */ '../views/customer/CustomerList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/employee',
        name: 'Employee',
        component: () =>
            import(
                /* webpackChunkName: "employee" */ '../views/employee/EmployeeScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "employee" */ '../views/employee/EmployeeList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/order',
        name: 'Order',
        component: () =>
            import(
                /* webpackChunkName: "order" */ '../views/order/OrderScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "order" */ '../views/order/OrderList'
                    ),
            },
            {
                path: 'order-status/list',
                component: () =>
                    import(
                        /* webpackChunkName: "order" */ '../views/order/OrderStatusList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/setting',
        name: 'Setting',
        component: () =>
            import(
                /* webpackChunkName: "setting" */ '../views/setting/SettingScreen'
            ),
        children: [
            {
                path: 'view',
                component: () =>
                    import(
                        /* webpackChunkName: "setting" */ '../views/setting/SettingView'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    store.commit(
        'setHideNav',
        to.matched.some((record) => record.meta.hideNav)
    );

    // Applies to any route with authRequired defined as true
    // TODO: Change to require auth by default, unless set otherwise
    if (to.matched.some((record) => record.meta.authRequired)) {
        if (!store.getters.getIsAuthenticated) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
