import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router';
import store from './store';
import VueCardFormat from 'vue-credit-card-validation';
import './assets/css/style.css';
import '@/helpers/filters';

import axios from 'axios';

axios.interceptors.request.use(function (config) {
    config.headers.Authorization =
        'Bearer ' + localStorage.getItem('authToken');

    return config;
});

Vue.config.productionTip = false;

Vue.use(VueCardFormat);

let app;

const initialize = () => {
    if (!app) {
        app = new Vue({
            vuetify,
            router,
            store,
            render: (h) => h(App),
        }).$mount('#app');
    }
};

store.dispatch('checkLoggedIn').then((isLoggedIn) => {
    if (isLoggedIn) {
        store.dispatch('initialize').then(() => {
            initialize();
        });
    } else {
        initialize();
    }
});
